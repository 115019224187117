body.dark-theme {
    background-color: #121212;
    color: #e0e0e0;
}

body.dark-theme .nav-wrapper {
    background-color: #1f1f1f;
}

body.dark-theme nav a {
    color: rgba(238, 110, 115, 0.7);
}

body.dark-theme .sidenav {
    background-color: #1f1f1f;
}
body.dark-theme .sidenav li>a,body.dark-theme .sidenav li>a>i.material-icons {
    color: rgba(238, 110, 115, 0.7);
}

body.dark-theme .card,body.dark-theme .card-panel {
    background-color: #1f1f1f;
}

body.dark-theme .btn, 
body.dark-theme .btn-large {
    background-color: #424242;
}

body.dark-theme .btn:hover, 
body.dark-theme .btn-large:hover {
    background-color: #616161;
}

body.dark-theme input[type="text"], 
body.dark-theme input[type="password"], 
body.dark-theme input[type="email"], 
body.dark-theme input[type="search"], 
body.dark-theme textarea {
    background-color: #1f1f1f;
    color: #e0e0e0;
    border-bottom: 1px solid #90caf9;
}

body.dark-theme input[type="text"]:focus, 
body.dark-theme input[type="password"]:focus, 
body.dark-theme input[type="email"]:focus, 
body.dark-theme input[type="search"]:focus, 
body.dark-theme textarea:focus {
    color: #e0e0e0 !important;
    border-bottom: 1px solid #90caf9 !important;
    box-shadow: 0 1px 0 0 #90caf9 !important;
    background-color: #505050 !important;
}

body.dark-theme .dropdown-content {
    background-color: #424242;
}

body.dark-theme .dropdown-content li>a, 
body.dark-theme .dropdown-content li>span {
    color: #e0e0e0;
}

body.dark-theme .dropdown-content li>a:hover, 
body.dark-theme .dropdown-content li>span:hover {
    background-color: #616161;
}

body.dark-theme .modal {
    background-color: #1f1f1f;
    color: #e0e0e0;
}

body.dark-theme .modal .modal-footer {
    background-color: #2a2a2a;
}

body.dark-theme .collection {
    border: 1px solid #252222;
}

body.dark-theme .collection .collection-item {
    background-color: #2a2a2a;
    border-bottom: 1px solid #403e3e;
}

body.dark-theme .tabs .tab {
    background-color: #1f1f1f;
}

body.dark-theme ul.pagination li.waves-effect:not(.active) a {
    color: #e0e0e0;
}

body.dark-theme ul.pagination li:not(.disabled) a {
    color: #e0e0e0;
}